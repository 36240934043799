import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";

import {AuthQueryKeys,  type IUser} from "~/shared";
import {api} from "~/api";

export const useSignInApi = () => useMutation({
    mutationKey: [AuthQueryKeys.SIGN_IN],
    mutationFn: async (provider: string) => {
        if (!provider) {
            throw new Error('Provider is required');
        }
        return await api.authControllerConnect(provider);
    }
});


export const useSignInCallbackApi = () => useMutation({
    mutationKey: [AuthQueryKeys.SIGN_IN_CALLBACK],
    mutationFn: async (args: { provider: string, code: string }) => {
        if (!args.provider || !args.code) {
            throw new Error('Both provider and code are required');
        }
        return await api.authControllerCallBack(args.provider, {code: args.code});
    }
});

export const useLogoutApi = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [AuthQueryKeys.LOGOUT],
        mutationFn: () => api.authControllerLogout(),
        onSuccess: async () => {
            await queryClient.resetQueries({ queryKey: [AuthQueryKeys.USER_PROFILE] });
        }
    });
};

export const useSignUpApi = () => useMutation({
    mutationKey: [AuthQueryKeys.SIGN_UP],
    mutationFn: api.authControllerSignUp
});

export const useLoginApi = () => useMutation({
    mutationKey: [AuthQueryKeys.LOGIN],
    mutationFn: api.authControllerSignIn
});




export const useUserProfileApi = () => {
    const headers = useRequestHeaders(["cookie", "connect.sid"]);

    return useQuery({
        queryKey: [AuthQueryKeys.USER_PROFILE],
        queryFn: () =>  api.userControllerGetUser({ headers }),
        retry: false,
    })
};


