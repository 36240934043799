import {
    applicationQueryKeys,
    AuthQueryKeys,
    FavoriteQueryKeys,
    TripQueryKeys,
    useLogoutApi,
    UserQueryKeys,
    useUserProfileApi
} from "@/shared";
import {computed } from "vue";
import {useQueryClient} from "@tanstack/vue-query";

export const useAuth = () => {
    const userProfileApi = useUserProfileApi();
    const logoutApi = useLogoutApi();

    const isAuthorized = computed(() => {
        return !!userProfileApi.data.value;
    });
    const queryClient = useQueryClient();
    const handleLogout = async () => {
        await logoutApi.mutateAsync()
        await Promise.all([
            queryClient.resetQueries({ queryKey: [AuthQueryKeys.USER_PROFILE] }),

            queryClient.resetQueries({ queryKey: [TripQueryKeys.CREATE_USER] }),
            queryClient.resetQueries({ queryKey: [TripQueryKeys.CREATE_USER] }),


            queryClient.resetQueries({ queryKey: [UserQueryKeys.GET_BY_ID] }),

            queryClient.resetQueries({ queryKey: [FavoriteQueryKeys.GET_MY_FAVORITE] }),
            queryClient.resetQueries({ queryKey: [FavoriteQueryKeys.GET_MY_FAVORITE_FULL] }),

            queryClient.resetQueries({ queryKey: [applicationQueryKeys.CREATE] }),
            queryClient.resetQueries({ queryKey: [applicationQueryKeys.GET_MY_STATUS] }),
            queryClient.resetQueries({ queryKey: [applicationQueryKeys.GET_ALL_STATUS_TRIPS] }),
            queryClient.resetQueries({ queryKey: [applicationQueryKeys.STATUS_REJECT] }),
            queryClient.resetQueries({ queryKey: [applicationQueryKeys.STATUS_REJECT] }),
            queryClient.resetQueries({ queryKey: [applicationQueryKeys.STATUS_ACCEPT] }),
            queryClient.resetQueries({ queryKey: [applicationQueryKeys.GET_ALL_BY_USER] }),
        ])
    }


    return {
        isAuthorized: isAuthorized,
        profile: userProfileApi.data,
        isLoadingProfile: userProfileApi.isLoading,
        isProfileSuccess: userProfileApi.isSuccess,
        handleLogout,
        suspenseProfile: userProfileApi.suspense,
    };
};
