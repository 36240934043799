import { default as _91provider_93T7PZPHWWn9Meta } from "/usr/src/app/src/pages/auth/callback/[provider].vue?macro=true";
import { default as indexthNL9Zi3HUMeta } from "/usr/src/app/src/pages/create-trip/index.vue?macro=true";
import { default as _91id_93blblMm6p7nMeta } from "/usr/src/app/src/pages/create-trip/successful/[id].vue?macro=true";
import { default as favoriteWlK2s6bRDfMeta } from "/usr/src/app/src/pages/favorite.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as privacyZP7BCLG19uMeta } from "/usr/src/app/src/pages/privacy.vue?macro=true";
import { default as indexy0y5CO0NRWMeta } from "/usr/src/app/src/pages/profile/[id]/index.vue?macro=true";
import { default as index6lyQQq8a9yMeta } from "/usr/src/app/src/pages/profile/setting/index.vue?macro=true";
import { default as links59dkYnpau1Meta } from "/usr/src/app/src/pages/profile/setting/links.vue?macro=true";
import { default as sign_45in02UZ9ebalVMeta } from "/usr/src/app/src/pages/sign-in.vue?macro=true";
import { default as sign_45ups3eIwBmJqmMeta } from "/usr/src/app/src/pages/sign-up.vue?macro=true";
import { default as bookingD1IJLWQiljMeta } from "/usr/src/app/src/pages/trip/[id]/booking.vue?macro=true";
import { default as indexDIQMTmrHgMMeta } from "/usr/src/app/src/pages/trip/[id]/index.vue?macro=true";
import { default as applications00QleSYcR3Meta } from "/usr/src/app/src/pages/trip/[id]/setting/applications.vue?macro=true";
import { default as indexCRqk8zfgiwMeta } from "/usr/src/app/src/pages/trip/[id]/setting/index.vue?macro=true";
import { default as searchF0GV4tbRmBMeta } from "/usr/src/app/src/pages/trip/search.vue?macro=true";
import { default as tripsTO5ny8Z6lOMeta } from "/usr/src/app/src/pages/trips.vue?macro=true";
import { default as component_45stubuYxk0V0rW5Meta } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuYxk0V0rW5 } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-callback-provider___ru___default",
    path: "/auth/callback/:provider()",
    component: () => import("/usr/src/app/src/pages/auth/callback/[provider].vue").then(m => m.default || m)
  },
  {
    name: "auth-callback-provider___ru",
    path: "/ru/auth/callback/:provider()",
    component: () => import("/usr/src/app/src/pages/auth/callback/[provider].vue").then(m => m.default || m)
  },
  {
    name: "auth-callback-provider___en",
    path: "/en/auth/callback/:provider()",
    component: () => import("/usr/src/app/src/pages/auth/callback/[provider].vue").then(m => m.default || m)
  },
  {
    name: "auth-callback-provider___es",
    path: "/es/auth/callback/:provider()",
    component: () => import("/usr/src/app/src/pages/auth/callback/[provider].vue").then(m => m.default || m)
  },
  {
    name: "create-trip___ru___default",
    path: "/create-trip",
    component: () => import("/usr/src/app/src/pages/create-trip/index.vue").then(m => m.default || m)
  },
  {
    name: "create-trip___ru",
    path: "/ru/create-trip",
    component: () => import("/usr/src/app/src/pages/create-trip/index.vue").then(m => m.default || m)
  },
  {
    name: "create-trip___en",
    path: "/en/create-trip",
    component: () => import("/usr/src/app/src/pages/create-trip/index.vue").then(m => m.default || m)
  },
  {
    name: "create-trip___es",
    path: "/es/create-trip",
    component: () => import("/usr/src/app/src/pages/create-trip/index.vue").then(m => m.default || m)
  },
  {
    name: "create-trip-successful-id___ru___default",
    path: "/create-trip/successful/:id()",
    component: () => import("/usr/src/app/src/pages/create-trip/successful/[id].vue").then(m => m.default || m)
  },
  {
    name: "create-trip-successful-id___ru",
    path: "/ru/create-trip/successful/:id()",
    component: () => import("/usr/src/app/src/pages/create-trip/successful/[id].vue").then(m => m.default || m)
  },
  {
    name: "create-trip-successful-id___en",
    path: "/en/create-trip/successful/:id()",
    component: () => import("/usr/src/app/src/pages/create-trip/successful/[id].vue").then(m => m.default || m)
  },
  {
    name: "create-trip-successful-id___es",
    path: "/es/create-trip/successful/:id()",
    component: () => import("/usr/src/app/src/pages/create-trip/successful/[id].vue").then(m => m.default || m)
  },
  {
    name: "favorite___ru___default",
    path: "/favorite",
    component: () => import("/usr/src/app/src/pages/favorite.vue").then(m => m.default || m)
  },
  {
    name: "favorite___ru",
    path: "/ru/favorite",
    component: () => import("/usr/src/app/src/pages/favorite.vue").then(m => m.default || m)
  },
  {
    name: "favorite___en",
    path: "/en/favorite",
    component: () => import("/usr/src/app/src/pages/favorite.vue").then(m => m.default || m)
  },
  {
    name: "favorite___es",
    path: "/es/favorite",
    component: () => import("/usr/src/app/src/pages/favorite.vue").then(m => m.default || m)
  },
  {
    name: "index___ru___default",
    path: "/",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___ru___default",
    path: "/privacy",
    component: () => import("/usr/src/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___ru",
    path: "/ru/privacy",
    component: () => import("/usr/src/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/usr/src/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___es",
    path: "/es/privacy",
    component: () => import("/usr/src/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-id___ru___default",
    path: "/profile/:id()",
    component: () => import("/usr/src/app/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-id___ru",
    path: "/ru/profile/:id()",
    component: () => import("/usr/src/app/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-id___en",
    path: "/en/profile/:id()",
    component: () => import("/usr/src/app/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-id___es",
    path: "/es/profile/:id()",
    component: () => import("/usr/src/app/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting___ru___default",
    path: "/profile/setting",
    component: () => import("/usr/src/app/src/pages/profile/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting___ru",
    path: "/ru/profile/setting",
    component: () => import("/usr/src/app/src/pages/profile/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting___en",
    path: "/en/profile/setting",
    component: () => import("/usr/src/app/src/pages/profile/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting___es",
    path: "/es/profile/setting",
    component: () => import("/usr/src/app/src/pages/profile/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting-links___ru___default",
    path: "/profile/setting/links",
    component: () => import("/usr/src/app/src/pages/profile/setting/links.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting-links___ru",
    path: "/ru/profile/setting/links",
    component: () => import("/usr/src/app/src/pages/profile/setting/links.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting-links___en",
    path: "/en/profile/setting/links",
    component: () => import("/usr/src/app/src/pages/profile/setting/links.vue").then(m => m.default || m)
  },
  {
    name: "profile-setting-links___es",
    path: "/es/profile/setting/links",
    component: () => import("/usr/src/app/src/pages/profile/setting/links.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___ru___default",
    path: "/sign-in",
    component: () => import("/usr/src/app/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___ru",
    path: "/ru/sign-in",
    component: () => import("/usr/src/app/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___en",
    path: "/en/sign-in",
    component: () => import("/usr/src/app/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___es",
    path: "/es/sign-in",
    component: () => import("/usr/src/app/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___ru___default",
    path: "/sign-up",
    component: () => import("/usr/src/app/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___ru",
    path: "/ru/sign-up",
    component: () => import("/usr/src/app/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/usr/src/app/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___es",
    path: "/es/sign-up",
    component: () => import("/usr/src/app/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-booking___ru___default",
    path: "/trip/:id()/booking",
    component: () => import("/usr/src/app/src/pages/trip/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-booking___ru",
    path: "/ru/trip/:id()/booking",
    component: () => import("/usr/src/app/src/pages/trip/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-booking___en",
    path: "/en/trip/:id()/booking",
    component: () => import("/usr/src/app/src/pages/trip/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-booking___es",
    path: "/es/trip/:id()/booking",
    component: () => import("/usr/src/app/src/pages/trip/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "trip-id___ru___default",
    path: "/trip/:id()",
    component: () => import("/usr/src/app/src/pages/trip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id___ru",
    path: "/ru/trip/:id()",
    component: () => import("/usr/src/app/src/pages/trip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id___en",
    path: "/en/trip/:id()",
    component: () => import("/usr/src/app/src/pages/trip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id___es",
    path: "/es/trip/:id()",
    component: () => import("/usr/src/app/src/pages/trip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting-applications___ru___default",
    path: "/trip/:id()/setting/applications",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/applications.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting-applications___ru",
    path: "/ru/trip/:id()/setting/applications",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/applications.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting-applications___en",
    path: "/en/trip/:id()/setting/applications",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/applications.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting-applications___es",
    path: "/es/trip/:id()/setting/applications",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/applications.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting___ru___default",
    path: "/trip/:id()/setting",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting___ru",
    path: "/ru/trip/:id()/setting",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting___en",
    path: "/en/trip/:id()/setting",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-id-setting___es",
    path: "/es/trip/:id()/setting",
    component: () => import("/usr/src/app/src/pages/trip/[id]/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "trip-search___ru___default",
    path: "/trip/search",
    component: () => import("/usr/src/app/src/pages/trip/search.vue").then(m => m.default || m)
  },
  {
    name: "trip-search___ru",
    path: "/ru/trip/search",
    component: () => import("/usr/src/app/src/pages/trip/search.vue").then(m => m.default || m)
  },
  {
    name: "trip-search___en",
    path: "/en/trip/search",
    component: () => import("/usr/src/app/src/pages/trip/search.vue").then(m => m.default || m)
  },
  {
    name: "trip-search___es",
    path: "/es/trip/search",
    component: () => import("/usr/src/app/src/pages/trip/search.vue").then(m => m.default || m)
  },
  {
    name: "trips___ru___default",
    path: "/trips",
    component: () => import("/usr/src/app/src/pages/trips.vue").then(m => m.default || m)
  },
  {
    name: "trips___ru",
    path: "/ru/trips",
    component: () => import("/usr/src/app/src/pages/trips.vue").then(m => m.default || m)
  },
  {
    name: "trips___en",
    path: "/en/trips",
    component: () => import("/usr/src/app/src/pages/trips.vue").then(m => m.default || m)
  },
  {
    name: "trips___es",
    path: "/es/trips",
    component: () => import("/usr/src/app/src/pages/trips.vue").then(m => m.default || m)
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/ru-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  }
]