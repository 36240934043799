/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type {
  AllMyApplicationDto,
  AuthConnect,
  CreateApplicationDto,
  CreateTripDto,
  CreateTripResponseDto,
  CurrencyDto,
  ErrorResponseDto,
  FavoriteDto,
  FavoriteGetDto,
  GetApplicationDto,
  GetStatusApplicationDto,
  GetTripsDto,
  GetUserDto,
  SignInDto,
  SignUpDto,
  SocialMediaTypesDto,
  TransitionTripDto,
  UpdateTripDto,
  UpdateUserDto,
} from "./data-contracts";
import type { RequestParams } from "./http-client";
import { ContentType, HttpClient } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User
   * @name UserControllerGetUser
   * @request GET:/api/user
   */
  userControllerGetUser = (params: RequestParams = {}) =>
    this.request<GetUserDto, void>({
      path: `/api/user`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerGetUserById
   * @request GET:/api/user/{id}
   */
  userControllerGetUserById = (id: string, params: RequestParams = {}) =>
    this.request<GetUserDto, void>({
      path: `/api/user/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerDeleteUser
   * @request DELETE:/api/user/delete/{id}
   */
  userControllerDeleteUser = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/user/delete/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpsertSocialMedia
   * @request POST:/api/user/social-media
   */
  userControllerUpsertSocialMedia = (data: SocialMediaTypesDto[], params: RequestParams = {}) =>
    this.request<SocialMediaTypesDto, void>({
      path: `/api/user/social-media`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateUser
   * @request PATCH:/api/user/update/{userId}
   */
  userControllerUpdateUser = (userId: string, data: UpdateUserDto, params: RequestParams = {}) =>
    this.request<GetUserDto, void>({
      path: `/api/user/update/${userId}`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSignUp
   * @request POST:/api/auth/sign-up
   */
  authControllerSignUp = (data: SignUpDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/auth/sign-up`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSignIn
   * @request POST:/api/auth/sign-in
   */
  authControllerSignIn = (data: SignInDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/auth/sign-in`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerCallBack
   * @request GET:/api/auth/oauth/callback/{provider}
   */
  authControllerCallBack = (
    provider: string,
    query: {
      code: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/api/auth/oauth/callback/${provider}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerConnect
   * @request GET:/api/auth/oauth/connect/{provider}
   */
  authControllerConnect = (provider: string, params: RequestParams = {}) =>
    this.request<AuthConnect, void>({
      path: `/api/auth/oauth/connect/${provider}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerLogout
   * @request POST:/api/auth/logout
   */
  authControllerLogout = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/auth/logout`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags currency
   * @name CurrencyControllerGetAll
   * @request GET:/api/currency
   */
  currencyControllerGetAll = (params: RequestParams = {}) =>
    this.request<CurrencyDto[], any>({
      path: `/api/currency`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerFindTripsByUserId
   * @request GET:/api/trip/user
   */
  tripControllerFindTripsByUserId = (
    query: {
      status: string;
      sort: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetTripsDto[], any>({
      path: `/api/trip/user`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerFindOne
   * @summary Get trip by id
   * @request GET:/api/trip/{id}
   */
  tripControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.request<GetTripsDto, ErrorResponseDto>({
      path: `/api/trip/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerDelete
   * @request DELETE:/api/trip/{id}
   * @secure
   */
  tripControllerDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponseDto>({
      path: `/api/trip/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerUpdateTrip
   * @request POST:/api/trip/{id}/update
   * @secure
   */
  tripControllerUpdateTrip = (id: string, data: UpdateTripDto, params: RequestParams = {}) =>
    this.request<CreateTripResponseDto, ErrorResponseDto>({
      path: `/api/trip/${id}/update`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerUpdateStatus
   * @request POST:/api/trip/{id}/status
   * @secure
   */
  tripControllerUpdateStatus = (id: string, data: TransitionTripDto, params: RequestParams = {}) =>
    this.request<CreateTripResponseDto, ErrorResponseDto>({
      path: `/api/trip/${id}/status`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerTransitionFromPublished
   * @request POST:/api/trip/{id}/transition
   * @secure
   */
  tripControllerTransitionFromPublished = (id: string, data: TransitionTripDto, params: RequestParams = {}) =>
    this.request<CreateTripResponseDto, ErrorResponseDto>({
      path: `/api/trip/${id}/transition`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerFindAll
   * @summary Get all trips with pagination
   * @request GET:/api/trip
   */
  tripControllerFindAll = (
    query?: {
      /** Page number */
      page?: number;
      /** Limit of trips per page */
      limit?: number;
      /** Sort order for the trips */
      sort?: "asc" | "desc";
      /** Filter trips by status */
      status?: (
        | "CREATED"
        | "PENDING"
        | "PUBLISHED"
        | "REJECTED"
        | "COMPLETED"
        | "NOT_COMPLETED"
        | "ARCHIVED"
        | "DELETED"
      )[];
    },
    params: RequestParams = {},
  ) =>
    this.request<GetTripsDto[], any>({
      path: `/api/trip`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags trip
   * @name TripControllerCreate
   * @request POST:/api/trip/create
   * @secure
   */
  tripControllerCreate = (data: CreateTripDto, params: RequestParams = {}) =>
    this.request<CreateTripResponseDto, ErrorResponseDto>({
      path: `/api/trip/create`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags upload
   * @name FileUploadControllerCreate
   * @request POST:/api/upload
   */
  fileUploadControllerCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/upload`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags application
   * @name ApplicationControllerCreate
   * @request POST:/api/application/create
   * @secure
   */
  applicationControllerCreate = (data: CreateApplicationDto, params: RequestParams = {}) =>
    this.request<GetApplicationDto, ErrorResponseDto>({
      path: `/api/application/create`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags application
   * @name ApplicationControllerGetAll
   * @request GET:/api/application/{tripId}
   * @secure
   */
  applicationControllerGetAll = (tripId: string, params: RequestParams = {}) =>
    this.request<GetApplicationDto[], ErrorResponseDto>({
      path: `/api/application/${tripId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags application
   * @name ApplicationControllerAccept
   * @request PATCH:/api/application/{id}/accept
   */
  applicationControllerAccept = (id: string, params: RequestParams = {}) =>
    this.request<GetApplicationDto, any>({
      path: `/api/application/${id}/accept`,
      method: "PATCH",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags application
   * @name ApplicationControllerReject
   * @request PATCH:/api/application/{id}/reject
   * @secure
   */
  applicationControllerReject = (id: string, params: RequestParams = {}) =>
    this.request<GetApplicationDto, ErrorResponseDto>({
      path: `/api/application/${id}/reject`,
      method: "PATCH",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags application
   * @name ApplicationControllerGetStatus
   * @request GET:/api/application/status/{tripId}
   * @secure
   */
  applicationControllerGetStatus = (tripId: string, params: RequestParams = {}) =>
    this.request<GetStatusApplicationDto, ErrorResponseDto>({
      path: `/api/application/status/${tripId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags application
   * @name ApplicationControllerGetAllByUser
   * @request GET:/api/application/user/{userId}
   */
  applicationControllerGetAllByUser = (userId: string, params: RequestParams = {}) =>
    this.request<AllMyApplicationDto[], ErrorResponseDto>({
      path: `/api/application/user/${userId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags search-place
   * @name SearchPlaceControllerSearchPlace
   * @request GET:/api/search-place
   */
  searchPlaceControllerSearchPlace = (
    query: {
      q: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/search-place`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags favorites
   * @name FavoritesControllerAdd
   * @summary Add trip in my favorite
   * @request POST:/api/favorites/add
   * @secure
   */
  favoritesControllerAdd = (data: FavoriteDto, params: RequestParams = {}) =>
    this.request<FavoriteGetDto, void>({
      path: `/api/favorites/add`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags favorites
   * @name FavoritesControllerGetAll
   * @summary My favorite id
   * @request GET:/api/favorites
   * @secure
   */
  favoritesControllerGetAll = (params: RequestParams = {}) =>
    this.request<FavoriteGetDto[], void>({
      path: `/api/favorites`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags favorites
   * @name FavoritesControllerGetFullTrips
   * @summary My favorite trips
   * @request GET:/api/favorites/full
   * @secure
   */
  favoritesControllerGetFullTrips = (params: RequestParams = {}) =>
    this.request<GetTripsDto[], void>({
      path: `/api/favorites/full`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags favorites
   * @name FavoritesControllerRemove
   * @summary Remove from favorites
   * @request DELETE:/api/favorites/remove/{tripId}
   * @secure
   */
  favoritesControllerRemove = (tripId: string, params: RequestParams = {}) =>
    this.request<FavoriteDto, void>({
      path: `/api/favorites/remove/${tripId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
