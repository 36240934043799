import { FetchClient } from "@/shared/utils/fetchClient/fetchClient";

export const BackendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.trip-rift.com';

export const api = new FetchClient({
  baseUrl: import.meta.env.VITE_API_URL,
  options: {
    credentials: "include",
  }
})

export const baseHost =  import.meta.env.VITE_BASE_URL || ''
