
export const AuthQueryKeys = {
    SIGN_IN: "auth/signIn",
    SIGN_IN_CALLBACK: "auth/signInCallback",
    LOGOUT: "auth/logout",
    USER_PROFILE: "auth/userProfile",
    SIGN_UP: "auth/signUp",
    LOGIN: "auth/login",
} as const;



export const TripQueryKeys = {
    GET_ALL: "trip",
    GET_BY_ID: "trip/getById",
    PUBLISH_TRIP: 'trip/publish',
    UPDATE_TRIP: 'trip/update',
    GET_PUBLISHED: 'trip/published',
    GET_COMPLETED: 'trip/completed',
    CREATE_USER: 'trip/user'
} as const;


export const UserQueryKeys = {
    GET_BY_ID: "user/getById",
    UPDATE_USER: 'user/update'
} as const;


export const CurrencyQueryKeys = {
    GET_ALL: "currency/all",

} as const;

export const FavoriteQueryKeys = {
    ADD_FAVORITE: "trip/addFavorite/favorites",
    REMOVE_FAVORITE: "trip/removeFavorite/favorites",
    GET_MY_FAVORITE: 'trip/favorites',
    GET_MY_FAVORITE_FULL: 'trip/favorites/full'
} as const;


export const  applicationQueryKeys = {
    CREATE: "application/create",
    GET_MY_STATUS: 'application/getMyStatus',
    GET_ALL_STATUS_TRIPS: 'application/getAllStatusTrips',
    STATUS_REJECT: 'application/status/reject',
    STATUS_ACCEPT: 'application/status/accept',
    GET_ALL_BY_USER: 'application/getAllByUser'
} as const;

