import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoINSTAGRAM, LazySvgoTELEGRAM, LazySvgoTIKTOK, LazySvgoAddGray, LazySvgoAdd, LazySvgoAirplaneMain, LazySvgoAirplane, LazySvgoApple, LazySvgoArrowBottom, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoBurger, LazySvgoCalendar, LazySvgoCar, LazySvgoClocks, LazySvgoClose, LazySvgoEmail, LazySvgoFacebook, LazySvgoFavorite, LazySvgoFavoriteBlack, LazySvgoGeo, LazySvgoGithub, LazySvgoGlobe, LazySvgoGoogle, LazySvgoHome, LazySvgoJob, LazySvgoLgout, LazySvgoLinks, LazySvgoLocale, LazySvgoLogo, LazySvgoMap, LazySvgoMessage, LazySvgoNotifications, LazySvgoOk, LazySvgoPassengers, LazySvgoReverse, LazySvgoSearch, LazySvgoSelectIcon, LazySvgoSetting, LazySvgoShip, LazySvgoStar, LazySvgoSupport, LazySvgoTrain, LazySvgoTrash, LazySvgoTwitter, LazySvgoUser, LazySvgoVisibility, LazySvgoVisibilityOff, LazySvgoVk } from '#components'
const lazyGlobalComponents = [
  ["SvgoINSTAGRAM", LazySvgoINSTAGRAM],
["SvgoTELEGRAM", LazySvgoTELEGRAM],
["SvgoTIKTOK", LazySvgoTIKTOK],
["SvgoAddGray", LazySvgoAddGray],
["SvgoAdd", LazySvgoAdd],
["SvgoAirplaneMain", LazySvgoAirplaneMain],
["SvgoAirplane", LazySvgoAirplane],
["SvgoApple", LazySvgoApple],
["SvgoArrowBottom", LazySvgoArrowBottom],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoBurger", LazySvgoBurger],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCar", LazySvgoCar],
["SvgoClocks", LazySvgoClocks],
["SvgoClose", LazySvgoClose],
["SvgoEmail", LazySvgoEmail],
["SvgoFacebook", LazySvgoFacebook],
["SvgoFavorite", LazySvgoFavorite],
["SvgoFavoriteBlack", LazySvgoFavoriteBlack],
["SvgoGeo", LazySvgoGeo],
["SvgoGithub", LazySvgoGithub],
["SvgoGlobe", LazySvgoGlobe],
["SvgoGoogle", LazySvgoGoogle],
["SvgoHome", LazySvgoHome],
["SvgoJob", LazySvgoJob],
["SvgoLgout", LazySvgoLgout],
["SvgoLinks", LazySvgoLinks],
["SvgoLocale", LazySvgoLocale],
["SvgoLogo", LazySvgoLogo],
["SvgoMap", LazySvgoMap],
["SvgoMessage", LazySvgoMessage],
["SvgoNotifications", LazySvgoNotifications],
["SvgoOk", LazySvgoOk],
["SvgoPassengers", LazySvgoPassengers],
["SvgoReverse", LazySvgoReverse],
["SvgoSearch", LazySvgoSearch],
["SvgoSelectIcon", LazySvgoSelectIcon],
["SvgoSetting", LazySvgoSetting],
["SvgoShip", LazySvgoShip],
["SvgoStar", LazySvgoStar],
["SvgoSupport", LazySvgoSupport],
["SvgoTrain", LazySvgoTrain],
["SvgoTrash", LazySvgoTrash],
["SvgoTwitter", LazySvgoTwitter],
["SvgoUser", LazySvgoUser],
["SvgoVisibility", LazySvgoVisibility],
["SvgoVisibilityOff", LazySvgoVisibilityOff],
["SvgoVk", LazySvgoVk],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
