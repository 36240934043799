import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4F1kZh3YAB from "/usr/src/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_client_RZVNejKJBe from "/usr/src/app/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import nuxt_plugin_6wEQMY3tee from "/usr/src/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_ZGw8LlWmeq from "/usr/src/app/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import ClickOutside_iIBCNyW7qq from "/usr/src/app/src/plugins/ClickOutside.ts";
import vue_query_JC9gnf7CA1 from "/usr/src/app/src/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_4F1kZh3YAB,
  plugin_client_RZVNejKJBe,
  nuxt_plugin_6wEQMY3tee,
  plugin_ZGw8LlWmeq,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  ClickOutside_iIBCNyW7qq,
  vue_query_JC9gnf7CA1
]