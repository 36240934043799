
// @ts-nocheck


export const localeCodes =  [
  "ru",
  "en",
  "es"
]

export const localeLoaders = {
  "ru": [{ key: "../src/locales/weekDays/ru.json", load: () => import("../src/locales/weekDays/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_weekDays_ru_json" */), cache: true },
{ key: "../src/locales/privacy/ru.json", load: () => import("../src/locales/privacy/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_privacy_ru_json" */), cache: true },
{ key: "../src/locales/country/ru.json", load: () => import("../src/locales/country/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_country_ru_json" */), cache: true },
{ key: "../src/locales/lang/ru.json", load: () => import("../src/locales/lang/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_lang_ru_json" */), cache: true },
{ key: "../src/locales/ui/ru.json", load: () => import("../src/locales/ui/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_ui_ru_json" */), cache: true },
{ key: "../src/locales/auth/ru.json", load: () => import("../src/locales/auth/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_auth_ru_json" */), cache: true },
{ key: "../src/locales/seo/ru.json", load: () => import("../src/locales/seo/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_seo_ru_json" */), cache: true },
{ key: "../src/locales/createTrip/ru.json", load: () => import("../src/locales/createTrip/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_createTrip_ru_json" */), cache: true },
{ key: "../src/locales/tripCard/ru.json", load: () => import("../src/locales/tripCard/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_tripCard_ru_json" */), cache: true },
{ key: "../src/locales/booking/ru.json", load: () => import("../src/locales/booking/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_booking_ru_json" */), cache: true },
{ key: "../src/locales/setting/ru.json", load: () => import("../src/locales/setting/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_setting_ru_json" */), cache: true },
{ key: "../src/locales/application/ru.json", load: () => import("../src/locales/application/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_application_ru_json" */), cache: true },
{ key: "../src/locales/profile/ru.json", load: () => import("../src/locales/profile/ru.json" /* webpackChunkName: "locale__usr_src_app_src_locales_profile_ru_json" */), cache: true }],
  "en": [{ key: "../src/locales/weekDays/en.json", load: () => import("../src/locales/weekDays/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_weekDays_en_json" */), cache: true },
{ key: "../src/locales/privacy/en.json", load: () => import("../src/locales/privacy/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_privacy_en_json" */), cache: true },
{ key: "../src/locales/country/en.json", load: () => import("../src/locales/country/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_country_en_json" */), cache: true },
{ key: "../src/locales/lang/en.json", load: () => import("../src/locales/lang/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_lang_en_json" */), cache: true },
{ key: "../src/locales/ui/en.json", load: () => import("../src/locales/ui/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_ui_en_json" */), cache: true },
{ key: "../src/locales/auth/en.json", load: () => import("../src/locales/auth/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_auth_en_json" */), cache: true },
{ key: "../src/locales/seo/en.json", load: () => import("../src/locales/seo/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_seo_en_json" */), cache: true },
{ key: "../src/locales/createTrip/en.json", load: () => import("../src/locales/createTrip/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_createTrip_en_json" */), cache: true },
{ key: "../src/locales/tripCard/en.json", load: () => import("../src/locales/tripCard/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_tripCard_en_json" */), cache: true },
{ key: "../src/locales/booking/en.json", load: () => import("../src/locales/booking/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_booking_en_json" */), cache: true },
{ key: "../src/locales/setting/en.json", load: () => import("../src/locales/setting/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_setting_en_json" */), cache: true },
{ key: "../src/locales/application/en.json", load: () => import("../src/locales/application/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_application_en_json" */), cache: true },
{ key: "../src/locales/profile/en.json", load: () => import("../src/locales/profile/en.json" /* webpackChunkName: "locale__usr_src_app_src_locales_profile_en_json" */), cache: true }],
  "es": [{ key: "../src/locales/weekDays/es.json", load: () => import("../src/locales/weekDays/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_weekDays_es_json" */), cache: true },
{ key: "../src/locales/privacy/es.json", load: () => import("../src/locales/privacy/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_privacy_es_json" */), cache: true },
{ key: "../src/locales/country/es.json", load: () => import("../src/locales/country/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_country_es_json" */), cache: true },
{ key: "../src/locales/lang/es.json", load: () => import("../src/locales/lang/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_lang_es_json" */), cache: true },
{ key: "../src/locales/ui/es.json", load: () => import("../src/locales/ui/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_ui_es_json" */), cache: true },
{ key: "../src/locales/auth/es.json", load: () => import("../src/locales/auth/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_auth_es_json" */), cache: true },
{ key: "../src/locales/seo/es.json", load: () => import("../src/locales/seo/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_seo_es_json" */), cache: true },
{ key: "../src/locales/createTrip/es.json", load: () => import("../src/locales/createTrip/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_createTrip_es_json" */), cache: true },
{ key: "../src/locales/tripCard/es.json", load: () => import("../src/locales/tripCard/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_tripCard_es_json" */), cache: true },
{ key: "../src/locales/booking/es.json", load: () => import("../src/locales/booking/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_booking_es_json" */), cache: true },
{ key: "../src/locales/setting/es.json", load: () => import("../src/locales/setting/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_setting_es_json" */), cache: true },
{ key: "../src/locales/application/es.json", load: () => import("../src/locales/application/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_application_es_json" */), cache: true },
{ key: "../src/locales/profile/es.json", load: () => import("../src/locales/profile/es.json" /* webpackChunkName: "locale__usr_src_app_src_locales_profile_es_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "ru",
      "files": [
        "/usr/src/app/src/locales/weekDays/ru.json",
        "/usr/src/app/src/locales/privacy/ru.json",
        "/usr/src/app/src/locales/country/ru.json",
        "/usr/src/app/src/locales/lang/ru.json",
        "/usr/src/app/src/locales/ui/ru.json",
        "/usr/src/app/src/locales/auth/ru.json",
        "/usr/src/app/src/locales/seo/ru.json",
        "/usr/src/app/src/locales/createTrip/ru.json",
        "/usr/src/app/src/locales/tripCard/ru.json",
        "/usr/src/app/src/locales/booking/ru.json",
        "/usr/src/app/src/locales/setting/ru.json",
        "/usr/src/app/src/locales/application/ru.json",
        "/usr/src/app/src/locales/profile/ru.json"
      ],
      "language": "ru"
    },
    {
      "code": "en",
      "files": [
        "/usr/src/app/src/locales/weekDays/en.json",
        "/usr/src/app/src/locales/privacy/en.json",
        "/usr/src/app/src/locales/country/en.json",
        "/usr/src/app/src/locales/lang/en.json",
        "/usr/src/app/src/locales/ui/en.json",
        "/usr/src/app/src/locales/auth/en.json",
        "/usr/src/app/src/locales/seo/en.json",
        "/usr/src/app/src/locales/createTrip/en.json",
        "/usr/src/app/src/locales/tripCard/en.json",
        "/usr/src/app/src/locales/booking/en.json",
        "/usr/src/app/src/locales/setting/en.json",
        "/usr/src/app/src/locales/application/en.json",
        "/usr/src/app/src/locales/profile/en.json"
      ],
      "language": "en"
    },
    {
      "code": "es",
      "files": [
        "/usr/src/app/src/locales/weekDays/es.json",
        "/usr/src/app/src/locales/privacy/es.json",
        "/usr/src/app/src/locales/country/es.json",
        "/usr/src/app/src/locales/lang/es.json",
        "/usr/src/app/src/locales/ui/es.json",
        "/usr/src/app/src/locales/auth/es.json",
        "/usr/src/app/src/locales/seo/es.json",
        "/usr/src/app/src/locales/createTrip/es.json",
        "/usr/src/app/src/locales/tripCard/es.json",
        "/usr/src/app/src/locales/booking/es.json",
        "/usr/src/app/src/locales/setting/es.json",
        "/usr/src/app/src/locales/application/es.json",
        "/usr/src/app/src/locales/profile/es.json"
      ],
      "language": "es-ES"
    }
  ],
  "defaultLocale": "ru",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "./locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://trip-rift.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ru",
    "files": [
      {
        "path": "/usr/src/app/src/locales/weekDays/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/privacy/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/country/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/lang/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/ui/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/auth/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/seo/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/createTrip/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/tripCard/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/booking/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/setting/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/application/ru.json"
      },
      {
        "path": "/usr/src/app/src/locales/profile/ru.json"
      }
    ],
    "language": "ru"
  },
  {
    "code": "en",
    "files": [
      {
        "path": "/usr/src/app/src/locales/weekDays/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/privacy/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/country/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/lang/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/ui/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/auth/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/seo/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/createTrip/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/tripCard/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/booking/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/setting/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/application/en.json"
      },
      {
        "path": "/usr/src/app/src/locales/profile/en.json"
      }
    ],
    "language": "en"
  },
  {
    "code": "es",
    "files": [
      {
        "path": "/usr/src/app/src/locales/weekDays/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/privacy/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/country/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/lang/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/ui/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/auth/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/seo/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/createTrip/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/tripCard/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/booking/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/setting/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/application/es.json"
      },
      {
        "path": "/usr/src/app/src/locales/profile/es.json"
      }
    ],
    "language": "es-ES"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
