import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ruLocale from "dayjs/locale/ru";
import enLocale from "dayjs/locale/en";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);


export type DateOrNull = Date | null;

export const formatDate = (date: DateOrNull, formatString: string): string => {
    return dayjs(date).format(formatString) || '';
};

export const getUtcDate = (date: DateOrNull | string): string => {
    return dayjs.utc(date).format();
};

export const formatTime = (date: DateOrNull, formatString: string): string => {
    return dayjs(date).format(formatString) || '00:00';
};

export const getDateUserTimeZone = (date: string): Date => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dayjs.utc(date).tz(userTimezone).toDate();
}

export const formatDateRange = (startDate: string, endDate: string, lang: string): string => {
    if (!startDate || !endDate) return '';

    const startFormatted = toFormattedDate(startDate, lang);
    const endFormatted = toFormattedDate(endDate, lang);

    const startDayMonth = startFormatted.split(' ')[0];
    const endDayMonth = endFormatted.split(' ')[0];

    const startMonth = startFormatted.split(' ')[1];
    const endMonth = endFormatted.split(' ')[1];

    let year = startFormatted.split(' ')[2];
    if (year.length === 4) {
        year = year.slice(2);
    }

    let yearLabel = 'г.';
    if (lang === 'en') {
        yearLabel = 'yrs';
    }

    return `${startDayMonth} ${startMonth}. - ${endDayMonth} ${endMonth}. ${year}${yearLabel}`;
};


export const toFormattedDate = (date: string, lang: string): string => {
    if (!date) return '';

    const userTz = dayjs.tz.guess();
    const formattedDate = dayjs.utc(date).tz(userTz).format();

    if (lang === 'en') {
        dayjs.locale(enLocale); // Установка английской локали
        return dayjs(formattedDate).format("MMMM D, YYYY h:mm A");
    }
    if(lang === 'ru') {
        dayjs.locale(ruLocale); // Установка русской локали
        return dayjs(formattedDate).format("D MMMM YYYY HH:mm");
    }
    else {
        dayjs.locale(enLocale); // Установка английской локали
        return dayjs(formattedDate).format("MMMM D, YYYY h:mm A");
    }
};



