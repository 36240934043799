<script setup lang="ts">
import { useHead, useI18n, useSeoMeta } from "#imports";
import { useLocaleHead } from "#i18n";
import {useAuth} from "~/shared";
const { t } = useI18n();
import { ConfigProvider } from 'radix-vue'
const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true
});

useHead({
  htmlAttrs: {
    lang: () => i18nHead.value.htmlAttrs?.lang,
    dir: () => i18nHead.value.htmlAttrs?.dir
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])]
});

useSeoMeta({
  titleTemplate(titleChunk) {
    return titleChunk || "Title not defined";
  },
  ogTitle() {
    return t("seo.title");
  },
  description() {
    return t("seo.description");
  },
  ogDescription() {
    return t("seo.description");
  },
  applicationName: "Trip Rift",
  ogSiteName: "Trip Rift",
});

const { suspenseProfile } = useAuth()

await suspenseProfile()
const useIdFunction = () => useId()
</script>


<template>
  <ConfigProvider :use-id="useIdFunction">
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <NuxtNotifications
      position="bottom right"
      :speed="300"
    />
  </ConfigProvider>
</template>

<style lang="postcss">
@import "@/styles/global.pcss";
</style>
